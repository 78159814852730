import React from "react"
import { Link } from "gatsby"
import { FiFacebook, FiInstagram, FiTwitter, FiGithub, FiYoutube, FiLinkedin} from "react-icons/fi";

const Footer = ({footer, socials, general}) => {
  return (
    <section className="section py-12 bg-black text-white">
      <div className="site-container">
        <div className="footer__layout grid divide-y gap-12">
          <div className="footer_top grid lg:grid-cols-2 gap-8">
            <div className="footer__top-col flex flex-col gap-4">
              <h3 className="text-lg font-bold">{footer.siteInfoTitle}</h3>
              <p>{footer.siteInfoTagline}</p>
              <ul className="flex gap-8">
                {socials.social.map((link, index) => {
                  let iconRender

                  if (link.icon == "Facebook") {
                    iconRender = <FiFacebook />
                  }
                  if (link.icon == "Instagram") {
                    iconRender = <FiInstagram />
                  }
                  if (link.icon == "Twitter") {
                    iconRender = <FiTwitter />
                  }
                  if (link.icon == "Youtube") {
                    iconRender = <FiYoutube />
                  }
                  if (link.icon == "Github") {
                    iconRender = <FiGithub />
                  }
                  if (link.icon == "LinkedIn") {
                    iconRender = <FiLinkedin />
                  }
                  return (
                    <Link to={link.url}>{iconRender}</Link>
                  )
                })}
              </ul>
            </div>
            <div className="footer__top-col flex flex-col gap-4">
              <h3 className="text-lg font-bold">{footer.primaryMenuTitle}</h3>
              <nav className="flex flex-col gap-2">
                  {footer.primaryMenu.menuItems.map((link, index) => {
                    const internalLink = link.menuItemUrl.internalLink
                    const externalUrl = link.menuItemUrl.externalUrl
                    if (internalLink) {
                    const slug = link.menuItemUrl.internalLink.slug.current

                    if (slug === "home") {
                      return <Link to="/">{link.text}</Link>
                    } else {
                      return <Link to={"/" + slug}>{link.text}</Link>
                    }
                  } else {
                    return <Link to={externalUrl}>{link.text}</Link>
                  }
                  })}
              </nav>
            </div>
          </div>
          <div className="footer-bottom py-4">
            <nav  className="flex flex-col lg:flex-row gap-4 lg:gap-8">
                <p> © {new Date().getFullYear()} |  {general.siteTitle}</p>
                {footer.secondaryMenu.menuItems.map((link, index) => {
                    const internalLink = link.menuItemUrl.internalLink
                    const externalUrl = link.menuItemUrl.externalUrl
                    if (internalLink) {
                    const slug = link.menuItemUrl.internalLink.slug.current

                    if (slug === "home") {
                      return <Link to="/">{link.text}</Link>
                    } else {
                      return <Link to={"/" + slug}>{link.text}</Link>
                    }
                  } else {
                    return <Link to={externalUrl}>{link.text}</Link>
                  }
                  })}
            </nav>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
