import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Header from './header'
import Footer from './footer'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteLayoutQuery {
      general: sanitySettingsGeneral {
        siteTitle
        siteURL
        id
        logo {
          asset {
            altText
            gatsbyImageData(placeholder: NONE)
          }
        }
        logo_secondary {
          asset {
            altText
            gatsbyImageData(placeholder: NONE)
          }
        }
      }
      header: sanitySettingsHeader {
        id
        menuDesktopPrimary {
          id
          title
          slug {
            current
          }
          menuItems {
            menuItemUrl {
              externalUrl 
              internalLink {
                id
                title
                slug {
                  current
                }
              }
            }
            text
          }
        }
      }
      footer: sanitySettingsFooter {
        id
        siteInfoTitle
        siteInfoTagline
        primaryMenuTitle
        primaryMenu {
          id
          title
          slug {
            current
          }
          menuItems {
            text
            menuItemUrl {
              internalLink {
                id
                title
                slug {
                  current
                }
              }
              externalUrl
            }
          }
        }
        secondaryMenuTitle
        secondaryMenu {
          id
          title
          slug {
            current
          }
          menuItems {
            text
            menuItemUrl {
              internalLink {
                id
                title
                slug {
                  current
                }
              }
              externalUrl
            }
          }
        }
      },
      cookie: sanitySettingsCookie {
        id
        message
        enabled
        link {
          id
          title
          slug {
            current
          }
        }
      },
      promo: sanitySettingsPromo {
        id
        text
        link {
          id
          title
          slug {
            current
          }
        }
      },
      seo: sanitySettingsSeo {
        id
        metaTitle
        metaDesc
        shareTitle
        shareDesc
        shareGraphic {
          asset {
            id
            altText
            gatsbyImageData
          }
        }
        favicon {
          asset {
            altText
            id
            gatsbyImageData
          }
        }
        faviconLegacy {
          asset {
            id
            altText
            url
          }
        }
      }
      socials: sanitySettingsSocials {
        socialMenuTitle
        social {
          _key
          _type
          icon
          url
        }
      }
    }
  `)

  const {general, header, footer, cookie, promo, seo, socials} = data

  console.log("layout query", data)
  return (
    <>
    <Header header={header} site={general} />

    <main>{children}</main>
    
    <Footer footer={footer} socials={socials} general={general}/>
      
    </>
  )
}

export default Layout
