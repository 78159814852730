import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import "./header.scss"
import {
  motion,
  useViewportScroll,
  AnimatePresence,
  useCycle,
} from "framer-motion"
import { FiChevronRight, FiPlus, FiX } from "react-icons/fi"

const animHeader = {
  initial: {
    backgroundColor: "transparent",
  },
  visible: {
    // opacity: 1,
    // y: 0,
    backgroundColor: "transparent",
    color: "white",
    transition: {
      duration: 0.5,
      staggerChildren: 0.15,
      staggerDirection: 1,
      delay: 0.3,
      ease: [0.38, 0.005, 0.215, 1],
      when: "beforeChildren",
    },
  },
  // scrollUp: {
  //   backgroundColor: "black",
  //   color: "white",
  //   transition: {
  //     duration: 0.5,
  //     staggerChildren: 0.15,
  //     staggerDirection: 1,
  //     delay: 0.3,
  //     ease: [0.38, 0.005, 0.215, 1],
  //     when: "beforeChildren",
  //   },
  // },
  hidden: {
    // opacity: 0,
    // y: -30,
    backgroundColor: "black",
    color: "white",
    transition: {
      duration: 0.5,
      staggerChildren: 0.15,
      staggerDirection: 1,
      // delay: 0.3,
      ease: [0.38, 0.005, 0.215, 1],
      when: "beforeChildren",
    },
  },
}
const animLogo = {
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      staggerChildren: 0.15,
      staggerDirection: 1,
      delay: 0.3,
      ease: [0.38, 0.005, 0.215, 1],
      when: "beforeChildren",
    },
  },
  hidden: {
    x: -30,
    opacity: 0,
    transition: {
      duration: 0.5,
      staggerChildren: 0.15,
      staggerDirection: 1,
      // delay: 0.3,
      ease: [0.38, 0.005, 0.215, 1],
      when: "beforeChildren",
    },
  },
}
const animNav = {
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
      staggerChildren: 0.15,
      staggerDirection: 1,
      delay: 0.3,
      ease: [0.38, 0.005, 0.215, 1],
      when: "beforeChildren",
    },
  },
  hidden: {
    x: -30,
    transition: {
      duration: 0.5,
      staggerChildren: 0.15,
      staggerDirection: 1,
      // delay: 0.3,
      ease: [0.38, 0.005, 0.215, 1],
      when: "beforeChildren",
    },
  },
}

const Header = ({ header, site }) => {
  const data = { header, site }
  console.log("header", data)

  //scroll anim
  const [hidden, setHidden] = useState(false)

  const { scrollY } = useViewportScroll()

  function update() {
    if (scrollY.current < scrollY.prev) {
      setHidden(false)
      console.log("visible")
    }
    if (scrollY?.current > 100) {
      setHidden(true)
      console.log("hidden")
    }
  }

  useEffect(() => {
    // return scrollY.onChange(() => console.log(scrollY));
    return scrollY.onChange(() => update())
  })

  //drawer
  const [mobileOpen, cycleMobileOpen] = useCycle(false, true)
  // const [open, setOpen] = useState(false)

  return (
    <>
      <motion.header
        className="site-header"
        variants={animHeader}
        initial="initial"
        animate={hidden ? "hidden" : "visible"}
      >
        <motion.div
          className="site-container flex items-center justify-between"
          initial="hidden"
          animate="visible"
          variant={animNav}
        >
          <motion.h1 className="site-header__logo" variants={animLogo}>
            <Link to="/">
              {site.logo ? (
                <GatsbyImage
                  className="w-[180px]"
                  placeholder="none"
                  loading="eager"
                  placeholderStyle={{ visibility: "hidden" }}
                  image={site.logo.asset.gatsbyImageData}
                  alt={site.siteTitle}
                />
              ) : (
                <p>{site.siteTitle}</p>
              )}
            </Link>
          </motion.h1>
          <nav className="flex gap-8 hidden lg:flex uppercase text-sm tracking-wider font-semibold">
            {header.menuDesktopPrimary.menuItems.map((link, index) => {
              const internalLink = link.menuItemUrl.internalLink
              const externalUrl = link.menuItemUrl.externalUrl

              if (internalLink) {
                const slug = link.menuItemUrl.internalLink.slug.current

                if (slug === "home") {
                  return <Link to="/">{link.text}</Link>
                } else {
                  return <Link to={"/" + slug} className="underline-anim" activeClassName="is-current">{link.text}</Link>
                }
              } else {
                return <Link to={externalUrl} className="underline-anim" activeClassName="is-current">{link.text}</Link>
              }
            })}
          </nav>

          <button className="hamburger-menu" onClick={cycleMobileOpen}>
            <span></span>
            <span></span>
          </button>
        </motion.div>
      </motion.header>

      {/* drawer */}
      <AnimatePresence>
        {mobileOpen && (
          <motion.aside
            initial="closed"
            animate="open"
            exit="closed"
            variants={mobileMenu}
            className="bg-white text-black fixed z-50 top-0 bottom-0 left-0 w-[500px] "
          >
            <div className="p-6">
              <motion.button
                className="absolute top-2 right-2"
                onClick={cycleMobileOpen}
                variants={mobileClose}
              >
                <FiX className="h-[30px] w-[30px]" />
              </motion.button>

              <motion.div
                variants={mobileLink}
                style={{ margin: 0 }}
                className="flex flex-col gap-8"
              >
                <motion.nav
                  className="divide-y flex flex-col uppercase text-lg font-semibold"
                  variants={mobileLink}
                >
                  <Link to="/" className="py-4">
                    Home
                  </Link>
                  {header.menuDesktopPrimary.menuItems.map((link, index) => {
                    const internalLink = link.menuItemUrl.internalLink
                    const externalUrl = link.menuItemUrl.externalUrl

                    if (internalLink) {
                      const slug = link.menuItemUrl.internalLink.slug.current

                      if (slug === "home") {
                        return (
                          <motion.span variants={mobileLink} className="py-4">
                            <Link to="/">{link.text}</Link>
                          </motion.span>
                        )
                      } else {
                        return (
                          <motion.span variants={mobileLink} className="py-4">
                            <Link to={"/" + slug}>{link.text}</Link>
                          </motion.span>
                        )
                      }
                    } else {
                      return (
                        <motion.span variants={mobileLink} className="py-4">
                          <Link to={externalUrl}>{link.text}</Link>
                        </motion.span>
                      )
                    }
                  })}
                </motion.nav>
              </motion.div>
            </div>
          </motion.aside>
        )}
      </AnimatePresence>

      {/* overlay */}
      <AnimatePresence>
        {mobileOpen && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={motionOverlay}
            onClick={cycleMobileOpen}
            className="bg-neutral-50 opacity-50 text-orange-50 fixed top-0 bottom-0 left-0 right-0 z-10 cursor-pointer z-30"
          >
            asdf
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default Header

const mobileMenu = {
  open: {
    // top: "0",
    width: "350px",
    // x: 0,
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
      staggerDirection: 1,
      ease: [0.38, 0.005, 0.215, 1],
      duration: 0.6,
      when: "beforeChildren",
      type: "spring",
      // stiffness: 50
    },
  },
  closed: {
    // x: "-100%",
    width: 0,
    transition: {
      // staggerChildren: 0.05,
      // staggerDirection: -1,
      // delay: 1,
      duration: 0.4,
      ease: [0.38, 0.005, 0.215, 1],
      when: "afterChildren",
    },
  },
}

const mobileLink = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.38, 0.005, 0.215, 1],
      duration: 0.6,
    },
  },
  closed: {
    y: 10,
    opacity: 0,
    transition: {
      ease: [0.38, 0.005, 0.215, 1],
      duration: 0.3,
    },
  },
}
const mobileClose = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.38, 0.005, 0.215, 1],
      duration: 0.6,
    },
  },
  closed: {
    y: 0,
    opacity: 0,
    transition: {
      ease: [0.38, 0.005, 0.215, 1],
      duration: 0.3,
    },
  },
}

// motion overlay
const motionOverlay = {
  open: {
    // visibility: "visible",
    opacity: 0.3,
    // width: "100%",
    transition: {
      staggerChildren: 0.05,
      staggerDirection: 1,
      ease: [0.38, 0.005, 0.215, 1],
      duration: 0.8,
      when: "beforeChildren",
      type: "spring",
      // stiffness: 50
    },
  },
  closed: {
    // visibility: "hidden",
    opacity: 0,
    // width: 0,
    transition: {
      // staggerChildren: 0.05,
      // staggerDirection: -1,
      // delay: 1,
      duration: 0.6,
      ease: [0.38, 0.005, 0.215, 1],
      when: "afterChildren",
    },
  },
}
